const { EncryptStorage } = require("encrypt-storage");

export const getUserFromLocalStorage = () => {
  const result = {
    user: loadStorage("user", "localStorage"),
    token: loadStorage("token", "localStorage"),
  };
  const user = result.user ? result.user : null;
  const token = result.token ? result.token : null;
  return {
    user,
    token,
  };
};

export const encryptStorage = (storageType) => {
  const config = {
    prefix: "@tasweyah",
    storageType: storageType || "localStorage",
  };

  return new EncryptStorage("secret-key-value", config);
};

export const dispatchStorage = (key, data, storageType) => {
  encryptStorage(storageType).setItem(key, data);
};

export const loadStorage = (key, storageType) =>
  encryptStorage(storageType).getItem(key) ||
  JSON.parse(localStorage.getItem(key));

export const deleteStorage = (key, storageType) =>
  encryptStorage(storageType).removeItem(key);
