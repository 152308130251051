import React, { useEffect, useState } from "react";
import { getCollection, payFromHyperpay } from "./../services/webServices";
import _ from "lodash";
import Loader from "./../components/Loader";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const Payment = () => {
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [transaction, setTransaction] = useState("");
  const [error, setError] = useState("");
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const cardType = searchParams.get("cardType");

  const { t } = useTranslation("common");
  const lang = localStorage.getItem("lang");

  useEffect(() => {
    if (url) {
      const portalRoot = document.getElementById("portal-root");
      if (portalRoot) {
        const scriptDiv = document.createElement("div");
        scriptDiv.id = "payment-widget-dd";
        const script = document.createElement("script");
        script.src = url;
        scriptDiv.append(script);
        portalRoot.append(scriptDiv);
      }
    }
    return () => {
      const scriptElement = document.querySelectorAll("#payment-widget-dd");
      const portalRoot = document.querySelector("#portal-root");
      if (scriptElement?.length > 0) {
        scriptElement?.forEach((element) => portalRoot.removeChild(element));
      }
    };
  }, [url]);

  useEffect(() => {
    getUserCollection();
  }, []);

  const getUserCollection = async () => {
    try {
      const data = await getCollection(id);
      if (data?.error) {
        setError(data.error);
        setLoading(false);
        return;
      }
      pay(id);
      setTransaction(data);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const pay = async (id) => {
    try {
      if (!_.isEmpty(id)) {
        const payload = { collectionId: id, paymentBrand: cardType };
        const { redirectURL } = await payFromHyperpay(payload);
        if (redirectURL) {
          setUrl(redirectURL);
        }
      }
    } catch (err) {
      setError(err?.response?.data?.error);
      setLoading(false);
      return;
    } finally {
      setLoading(false);
    }
  };
  const myCss = `
  .wpwl-control {
    background-color: #fdfdfd;
    color: gray;
}`;
  return (
    <div className="flex items-center justify-center bg-gray-100">
      <div className="payment-container mt-6">
        <div className="dashboard-table-container card-layout ">
          <Helmet>
            <script type="text/javascript">{scriptOptions(t, lang)}</script>
          </Helmet>
          <div className="row">
            <div className="col-md-12">
              <div className="panel-primary"></div>
            </div>
          </div>

          <div id="portal-root"></div>
          {loading ? (
            <Loader />
          ) : error ? (
            <h1 className="text-center pt-10 ">{t(`payment.${error}`)}</h1>
          ) : url ? (
            <>
              <div className="text-center">
                <PaymentRow value={transaction?.refId} title={t("makeDeposit.refId")} />
                <PaymentRow value={transaction?.dueDate} title={t("makeDeposit.date")} />
                <PaymentRow
                  value={`${
                    transaction.currency ? transaction.currency + ":" : ""
                  } ${transaction.amount}`}
                  title={t("makeDeposit.total")}
                />
                <PaymentRow
                  value={t("makeDeposit.HyperpayGateway")}
                  title={t("makeDeposit.paymentMethod")}
                />
              </div>
              <form
                action={`${window?.location?.origin}/verify-payment`} //
                class="paymentWidgets"
                data-brands={cardType}
              ></form>
              <style>{myCss}</style>
            </>
          ) : (
            "something went wrong"
          )}
        </div>
      </div>
    </div>
  );
};

const PaymentRow = ({ title, value }) => {
  return (
    <div className="flex justify-between px-5 py-1 row-text">
      {value ? (
        <>
          <div className="col-md-7 " style={{ textAlign: "left" }}>
            {title || ""}
          </div>
          <div className="col-md-5 break-all">{value || ""}</div>
        </>
      ) : null}
    </div>
  );
};
const scriptOptions = (t, lang) => `
      function validateHolder(e) {
        var holder = $('.wpwl-control-cardHolder').val();
        if (holder.trim().length < 2) {
          $('.wpwl-control-cardHolder').addClass('wpwl-has-error').after('<div class="wpwl-hint wpwl-hint-cardHolderError">Invalid card holder</div>');
          return false;
        }
        return true;
      }
      function validateCardHolderName(e) {
        var newValue = e.target.value;
        // Regular expression to match only letters and spaces

        if(newValue.length < 5){
          $('.wpwl-control-cardHolder').addClass('wpwl-has-error').after('<div class="wpwl-hint wpwl-hint-cardHolderError">${t(
            "makeDeposit.minFiveCharacters"
          )}</div>')
          return
        }

        var regex = /^[A-Za-z ]+$/;
        if (!regex.test(newValue)) {
          $('.wpwl-control-cardHolder').addClass('wpwl-has-error').after('<div class="wpwl-hint wpwl-hint-cardHolderError">${t(
            "makeDeposit.invalidCardHolder"
          )}</div>')
        }
      }
    var wpwlOptions = {
      locale: "${lang === "en" ? "en" : "ar"}",
      iframeStyles: {
        'card-number-placeholder': {
          'color': ' #888',
          'font-size': '16px',
          'font-family': 'Red Hat Display Medium'
        },
        'cvv-placeholder': {
          'color': ' #888',
          'font-size': '16px',
          'font-family': 'Red Hat Display Medium'
        },
        'billingAddress': {
          color: 'gray',
          'font-size': '16px',
          'font-family': 'Arial',
          backgroundColor: 'green'
        },
        'billingAddress-country': { // Style for the country field
          color: 'blue', // Change the text color here
          'font-size': '16px', // Adjust font size if needed
          'font-family': 'Arial' // Adjust font family if needed
        },
        
      },
      billingAddress: {
        country: "",
        city:"",
        postcode: "",
        street1: "",
        street2: "",
        givenName: "", // Set the customer's given name
        surname: "",   // Set the customer's surname
        email: ""
      },
      mandatoryBillingFields: {
        cardHolderName:true,
        country: true,
        city: false,
        postcode: false,
        street1: false,
        cardNumber:true,
        givenName: true,
        surname: true,
        email: true,
      },
      maskCvv: true,
      style: "card",
      showCVVHint: true,
      brandDetection: true,
         showCVVHint: true,
         brandDetection: true,
        onReady: function () {
          $('form.wpwl-form-card').find('.wpwl-button').on('click', function (e) {
            validateHolder(e);
          });
          $(".wpwl-label-cvv").html("CVV *");  
          $(".wpwl-label-cardHolder").html("${t(
            "makeDeposit.cardHolderNameHP"
          )}");  
          $(".wpwl-control-cardHolder").on("change", function(e) {
          validateCardHolderName(e)
        });
          $(".wpwl-control-cardHolder").on("focus", function(e) {
          validateCardHolderName(e)
        });
          $(".wpwl-label-expiry").html("${t("makeDeposit.expireDate")}");  
          $(".wpwl-label-cardNumber").html("${t(
            "makeDeposit.cardNumberHP"
          )}");  
          $(".wpwl-label-billing").html("${t("makeDeposit.billingAddress")}"); 
          $(".wpwl-button-pay").html("${t("makeDeposit.deposit")}"); 
          $(".wpwl-control-stateText").remove(); 
        },
  
}
    
`;

export default Payment;
