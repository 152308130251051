import React, { useEffect, useState } from "react";
import Loader from "./../components/Loader";
import { verifyPayment } from "./../services/webServices";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";

const VerifyPayment = () => {
  const [success, setSuccess] = useState(false);
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(true);
  const [thankYouMessage, setThankYouMessage] = useState("");
  const { t, i18n } = useTranslation("common");

  const getParamsFromURL = (urlString, key) => {
    const regex = /[?&]([^=#]+)=([^&#]*)/g;
    const params = {};
    let match;
    while ((match = regex.exec(urlString))) {
      params[match[1]] = match[2];
    }

    return params[key];
  };

  const handlePayment = async (id) => {
    const lang = localStorage.getItem("lang");
    try {
      const res = await verifyPayment({ id, lang });
      setThankYouMessage(res?.key);
      setSuccess(res);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const urlId = getParamsFromURL(window.location.href, "id");
    if (urlId) {
      setId(urlId);
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(id)) {
      handlePayment(id);
      setId("");
    }
  }, [id]);

  return (
    <div className="h-screen flex items-center justify-center ">
      <div className="payment-container">
        <div className="dashboard-table-container card-layout">
          <div className="row">
            <div className="col-md-12">
              <div className="panel-primary">
                <div className="">
                  {loading ? (
                    <Loader />
                  ) : (
                    <>
                      {success
                        ? thankYouMessage
                        : `${t("verifyPayment.paymentFailure")}`}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyPayment;
