import React, { useEffect, useState } from "react";
import { Layout, theme } from "antd";
import langIcon from "../assets/ic-header-language.png";
import logo from "../assets/logo.png";
import { useTranslation } from "react-i18next";
import { BsCollection } from "react-icons/bs";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const { Header, Content, Sider } = Layout;

const Sidebar = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const { i18n, t } = useTranslation("common");
  const [isLangMenuOpen, setIsLangMenuOpen] = useState(false);

  const handleLangDropDown = () => {
    setIsLangMenuOpen((preValue) => !preValue);
  };

  const location = useLocation();
  const [activeTab, setActiveTab] = useState({
    collections: true,
  });

  useEffect(() => {
    if (location.pathname === "/") {
      setActiveTab({
        collections: true,
      });
    }
  }, [location]);

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    document.body.setAttribute("dir", lang === "ar" ? "rtl" : "ltr");
    localStorage.setItem("lang", lang);
  };

  return (
    <Layout className="h-screen side-bar-menu-button">
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          // console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          // console.log(collapsed, type);
        }}
        style={{
          backgroundColor: "#2f316a",
        }}
      >
        <div className="mx-auto p-2 bg-[#fff]">
          <img src={logo} className="w-[150px]" alt="collection portal logo" />
        </div>
        {/* <nav className="mt-5 px-2 space-y-1">
          <ul>
            <li className="mb-3">
              <Link
                to="/"
                className={`flex items-center rounded-md px-3 py-2 text-sm font-medium ${
                  activeTab.collections
                    ? "text-white bg-green hover:text-white"
                    : "text-gray-300 hover:bg-secondary hover:text-white"
                }`}
              >
                <svg className="fill-current h-5 w-5 mx-2" viewBox="0 0 24 24">
                  <BsCollection className="text-xl" />
                </svg>
                {t("collections.title")}
              </Link>
            </li>
          </ul>
        </nav> */}
      </Sider>
      <Layout>
        <Header
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#2f316a",
          }}
        >
          <div className="demo-logo" />

          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start"></div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <button
              type="button"
              className="rounded-full bg-lightSecondary p-1 text-gray-400 hover:text-primary focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
            >
              <span className="sr-only">{t("misc.notificationsView")}</span>
              <svg
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
                />
              </svg>
            </button>
          </div>
          <div className={`relative ml-3`}>
            <div>
              <button
                type="button"
                className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                id="user-menu-button"
                aria-expanded="false"
                aria-haspopup="true"
                onClick={handleLangDropDown}
              >
                <span className="sr-only">{t("misc.usermenuOpen")}</span>
                <img className="h-4 w-4 rounded-full" src={langIcon} alt="" />
              </button>
            </div>
            {isLangMenuOpen && (
              <div
                className="absolute right-0 z-10 mt-2 w-15 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="user-menu-button"
                tabIndex={-1}
              >
                <a
                  href="#disable"
                  className="block px-2 py-1 text-sm text-gray-700"
                  role="menuitem"
                  tabIndex={-1}
                  id="user-menu-item-0"
                  onClick={() => {
                    i18n.changeLanguage("en");
                    setIsLangMenuOpen(false);
                    handleLanguageChange("en");
                  }}
                >
                  EN
                </a>
                <a
                  href="#disable"
                  className="block px-2 py-1 text-sm text-gray-700"
                  role="menuitem"
                  tabIndex={-1}
                  id="user-menu-item-1"
                  onClick={() => {
                    i18n.changeLanguage("ar");
                    setIsLangMenuOpen(false);
                    handleLanguageChange("ar");
                  }}
                >
                  AR
                </a>
              </div>
            )}
          </div>
          {/* <div className="relative ml-3 cursor-pointer">
            <FiLogOut className="text-xl text-white" />
          </div> */}
        </Header>
        <Content>
          <main
            className="px-4 mb-5 sm:px-6 lg:px-8 h-full overflow-auto"
            style={{ background: colorBgContainer }}
          >
            {/* Main content goes here */}
            <div className=" w-full py-5">
              <Outlet />
            </div>
          </main>
        </Content>
      </Layout>
    </Layout>
  );
};
export default Sidebar;
