import axiosClient from "./axios";
import {
  API_GET_COLLECTION,
  API_PAY_FROM_HYPERPAY,
  API_PAY_FROM_HYPERPAY_VERIFY,
} from "./../constants/api";

export async function getCollection(id) {
  const response = await axiosClient.get(API_GET_COLLECTION, {
    params: {
      id,
    },
  });
  return response.data;
}

export async function payFromHyperpay(payload) {
  const response = await axiosClient.post(API_PAY_FROM_HYPERPAY, payload);
  return response.data;
}

export async function verifyPayment(payload) {
  const response = await axiosClient.get(API_PAY_FROM_HYPERPAY_VERIFY, {
    params: payload,
  });
  return response.data;
}
