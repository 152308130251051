import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import madaLogo from "../assets/mada.webp";
import masterCardLogo from "../assets/mastercardLogo.webp";
import visaLogo from "../assets/visaLogo.webp";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { getCollection } from "../services/webServices";
import { useTranslation } from "react-i18next";
import Loader from "../components/Loader";
import moment from "moment";
import logo from "../assets/logo.png";

const PaymentBrand = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [isPaid, setIsPaid] = useState(null);
  const [dueDatePass, setDueDatePass] = useState(null);
  const { t } = useTranslation("common");

  const handlePaymentMethod = async (cardType, id) => {
    if (!id || !cardType) {
      setError("Card Type Is Required");
      return;
    }
    const url = `/checkout?id=${id}&cardType=${cardType}`;
    navigate(url);
  };

  const checkIsPaidOrNot = async (id) => {
    try {
      const data = await getCollection(id);
      setIsPaid(data?.paid);
      if (data?.dueDate) {
        const currentTimeStamps = new Date().getTime();
        const dueDateTimeStamps = moment(
          data?.dueDate,
          "YYYY-MM-DD hh:mm:ss a"
        ).valueOf();
        if (currentTimeStamps >= dueDateTimeStamps) {
          setDueDatePass(true);
        }
      }
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!id) {
      return;
    }
    checkIsPaidOrNot(id);
  }, [id]);

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col items-center justify-center rounded-lg">
      <div className="mx-auto mb-8">
        <img src={logo} className="w-[250px]" alt="collection portal logo" />
      </div>
      {loading ? (
        <Loader />
      ) : isPaid ? (
        <div className="bg-white p-8 rounded shadow-md w-6/12 space-y-3 px-10">
          <h1 className="text-center pt-5">
            {t("payment.invoiceAlreadyPaid")}
          </h1>
        </div>
      ) : dueDatePass ? (
        <div className="bg-white p-8 rounded shadow-md w-6/12 space-y-3 px-10">
          <h1 className="text-center pt-5">{t("payment.invoiceDuePassed")}</h1>
        </div>
      ) : (
        <>
          <div className="bg-white rounded shadow-md w-[95%] md:w-[60%] lg:w-[45%] p-10 space-y-3">
            <Formik
              initialValues={{
                cardType: "",
                agreeToTerms: false,
              }}
              validate={(values) => {
                const errors = {};
                if (!values.cardType) {
                  errors.cardType = "Card type is required";
                }
                return errors;
              }}
              onSubmit={(values) => {
                handlePaymentMethod(values.cardType, id);
              }}
            >
              {({ values }) => (
                <Form>
                  <div className="mb-4">
                    <label
                      htmlFor="cardType"
                      className="block text-gray-700 font-bold mb-2"
                    >
                      {t("payment.selectCardType")}
                    </label>
                    <Field
                      as="select"
                      id="cardType"
                      name="cardType"
                      className="border border-gray-300 rounded p-2 w-full"
                    >
                      <option value="" disabled>
                        {t("payment.selectACardType")}
                      </option>
                      <option value="MASTER">{t("payment.MASTER")}</option>
                      <option value="VISA">{t("payment.VISA")}</option>
                      <option value="MADA">{t("payment.MADA")}</option>
                    </Field>
                    <ErrorMessage
                      name="cardType"
                      component="div"
                      className="text-red-500 mt-2"
                    />
                  </div>

                  {values.cardType && (
                    <div className="flex justify-between items-center">
                      <div className="text-gray-600">
                        {t("payment.selectedCard")}
                        <span className="font-bold mx-1">
                          {t("payment." + values.cardType).toUpperCase()}
                        </span>
                      </div>
                      <div>
                        <img
                          src={
                            values.cardType === "MASTER"
                              ? masterCardLogo
                              : values.cardType === "VISA"
                              ? visaLogo
                              : madaLogo
                          }
                          alt={`${values.cardType} Logo`}
                          className="h-8"
                        />
                      </div>
                    </div>
                  )}

                  <div className="mt-4">
                    {/* Uncomment this section if needed */}
                    {/* <div className="mb-4 flex flex-col">
                      <div className="flex items-center">
                        <Field
                          type="checkbox"
                          id="agreeToTerms"
                          name="agreeToTerms"
                          className="mr-2"
                        />
                        <label
                          className="block text-gray-700 font-bold"
                          htmlFor="agreeToTerms"
                        >
                          Agree to Terms and Conditions
                        </label>
                      </div>
                      <ErrorMessage
                        name="agreeToTerms"
                        component="div"
                        className="text-red-500 mt-2"
                      />
                    </div> */}

                    <button
                      type="submit"
                      className="bg-secondary text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                      {t("payment.proceed")}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>

          <div className="my-6 flex items-center justify-center w-[95%] md:w-[60%] lg:w-[45%]">
            <hr className="w-[48%]" />
            <span className="text-green font-bold mx-4">
              {t("payment.orOption")}
            </span>
            <hr className="w-[48%]" />
          </div>

          <div className="bg-white rounded shadow-md w-[95%] md:w-[60%] lg:w-[45%] p-8 space-y-3">
            <p className="font-semibold leading-[20px] mb-[5px]">
              {t("payment.bankTransfer")}
            </p>
            <span className="font-semibold">{t("payment.sabbBank")}</span>
            <p className="text-secondary font-bold">
              {t("payment.accountNumber")}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentBrand;
