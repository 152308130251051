import React from "react";
import { useTranslation } from "react-i18next";

const Welcome = () => {
  const { t } = useTranslation("common");
  return (
    <div className="welcome-container container">
      <h1>{t("welcome.heading1")}</h1>
    </div>
  );
};

export default Welcome;
