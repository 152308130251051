import axios from "axios";
import { getUserFromLocalStorage } from "../utils/localStorage";
import { defaultServerURL } from "./../constants/api";

const axiosClient = axios.create({
  baseURL: defaultServerURL,
});

axiosClient.interceptors.request.use((config) => {
  const user = getUserFromLocalStorage();
  if (user.token) {
    const token = user.token;
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  return config;
});

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    return Promise.reject(error);
  }
);

export default axiosClient;
