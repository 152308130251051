export const localServer = "http://localhost:8080";
export const devServerURL = "https://collection-portal.herokuapp.com";
export const stagingServerURL = "https://stage.tasweyah.sa";
export const liveServerURL = "https://portal.collectd.com.sa";
const NODE_ENV = "production";
let serverURL;

switch (NODE_ENV) {
  case "development":
    serverURL = devServerURL;
    break;
  case "staging":
    serverURL = stagingServerURL;
    break;
  case "production":
    serverURL = liveServerURL;
    break;
  default:
    serverURL = localServer;
}

export const defaultServerURL = serverURL;
export const API = "/api";

export const API_GET_COLLECTION = `${API}/collection`;

export const API_PAY_FROM_HYPERPAY = `${API}/payment`;
export const API_PAY_FROM_HYPERPAY_VERIFY = `${API_PAY_FROM_HYPERPAY}/verify`;
