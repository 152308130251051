import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";

import i18next from "i18next";
import common_en from "./translations/en/common.json";
import common_ar from "./translations/ar/common.json";

import { store } from "./app/store";
import { Provider } from "react-redux";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    en: { common: common_en },
    ar: { common: common_ar },
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <I18nextProvider i18n={i18next}>
          <App />
        </I18nextProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
