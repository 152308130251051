import React, { Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "./components/Sidebar";
import "./App.css";
import Payment from "./pages/Payment";
import VerifyPayment from "./pages/VerifyPayment";
import PageNotFound from "./pages/PageNotFound";
import { useTranslation } from "react-i18next";
import PaymentBrand from "./pages/PaymentBrand";
import Welcome from "./pages/Welcome";

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (!lang) {
      localStorage.setItem("lang", "ar");
    }

    i18n.changeLanguage(lang || "ar"); // Change the language to the one stored in local storage or Arabic if not found.
    document.body.setAttribute("dir", lang === "ar" || !lang ? "rtl" : "ltr");
  }, [i18n]);

  return (
    <Suspense fallback="loading">
      <div className="h-screen bg-tertiary ">
        <ToastContainer />

        <Routes>
          <Route element={<Sidebar />}>
            <Route path="/" element={<Welcome />} />
            <Route path="/payment-method/:id?" element={<PaymentBrand />} />
            <Route path="/checkout" element={<Payment />} />
            <Route path="/verify-payment" element={<VerifyPayment />} />
          </Route>
          <Route path="/*" element={<PageNotFound />} />
        </Routes>
      </div>
    </Suspense>
  );
}

export default App;
